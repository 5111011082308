<template>
  <div class="container-fluid p-0 m-0">
    <div
      id="login-wrapper"
      class="d-md-flex justify-content-center login-wrapper vh-100"
    >
      <div class="login-form d-flex align-items-center px-5">
        <div class="login-form--block mx-auto">
          <b-img
            src="/media/images/drsg-login-logo.svg"
            fluid
            alt="Responsive image"
          />
          <b-form @submit.prevent="onSubmit" class="g-3 needs-validation">
            <b-form-group
              id="input-group-1"
              label="Username"
              label-for="username"
            >
              <b-form-input
                id="username"
                v-model="form.username"
                placeholder="Enter username"
                :class="{ 'is-invalid': $v.form.username.$error }"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              id="input-group-2"
              label="Password:"
              label-for="password"
            >
              <b-form-input
                id="password"
                type="password"
                v-model="form.password"
                placeholder="Enter password"
                :class="{ 'is-invalid': $v.form.password.$error }"
              ></b-form-input>
              <div class="d-flex flex-column">
                <small
                  class="text-danger mt-2 invalid-feedback d-block"
                  v-if="loginFailedMsg"
                >
                  {{ loginFailedMsg }}
                </small>
                <p class="mt-2">
                  <b-link
                    data-cy="forgot-password"
                    v-b-modal.reset-password-modal
                    >Forgot password?
                  </b-link>
                </p>
              </div>
            </b-form-group>
            <div
              class="form-group d-lg-flex align-items-center"
              role="group"
              :class="{ 'form-group--error': isFailed }"
            >
              <b-button
                id="login-btn"
                type="submit"
                variant="brand-primary"
                :disabled="showLoader"
                :class="{ 'is-invalid': isFailed }"
              >
                <b-spinner small v-if="showLoader"></b-spinner>
                Login
              </b-button>
            </div>
          </b-form>
          <hr />
          <div class="login-info">
            <h5 class="title">New to DRSG?</h5>
            <p class="description">
              Please contact to us to register and start set up your pricing.
              <a href="mailto:miwalker@dufresne.ca">Contact Us</a>
            </p>
          </div>
        </div>
      </div>
      <div class="login-image d-none d-lg-block d-xl-block">
        <b-img
          src="/media/images/login-image.png"
          class="w-100 h-100"
          alt="Responsive image"
        />
      </div>
    </div>
    <b-modal
      id="reset-password-modal"
      centered
      hide-header
      hide-footer
      ref="reset-password-modal"
      size="sm"
      no-close-on-backdrop
      data-cy="open-modal"
    >
      <div class="d-flex flex-column" v-if="!isResetpasswordFormHide">
        <b-form
          @submit.prevent="onSubmitResetPasswordForm"
          class="g-3 needs-validation"
        >
          <div class="message-detail text-center">
            <h5 class="msg-title">Reset Your Password</h5>
          </div>
          <small>
            Enter the email address associated with your account, and we’ll send
            you a link to reset your password.
          </small>
          <div class="my-2">
            <Label>Email</Label>
            <b-form-input
              id="email"
              v-model="resetPasswordForm.email"
              placeholder="Enter your email"
              :class="{ 'is-invalid': $v.resetPasswordForm.email.$error }"
              data-cy="reset-email"
            ></b-form-input>
            <small
              class="text-danger mt-2 invalid-feedback d-block"
              v-if="!$v?.resetPasswordForm?.email?.email"
            >
              Please enter a valid email address.
            </small>
          </div>
          <small>
            Make sure to check your spam or junk folder if you don’t receive the
            email shortly.
          </small>
          <div class="d-flex justify-content-between mt-3">
            <b-button
              @click="onCancelResetPasswordForm()"
              type="button"
              variant="link"
              data-cy="cancel-reset"
            >
              Cancel
            </b-button>
            <b-button
              type="submit"
              variant="brand-primary"
              data-cy="send-reset-link"
            >
              <b-spinner small v-if="showResetPasswordLoader"></b-spinner>
              Send Reset Link
            </b-button>
          </div>
        </b-form>
      </div>
      <div class="d-flex flex-column" data-cy="success-modal" v-else>
        <div class="message-detail text-center">
          <h5 class="msg-title">Password Reset Email Sent</h5>
        </div>
        <small>
          If you have an active account an email will be sent shortly, with a
          link to reset your password. The link will expire in 30 minutes
        </small>
        <small class="my-3">
          If you don't see the email in your inbox, be sure to check your spam
          and junk folders
        </small>
        <div class="d-flex justify-content-between mt-3">
          <b-button
            @click="onCancelResetPasswordForm"
            type="button"
            variant="link"
          >
            Back to Login
          </b-button>
          <b-button
            type="button"
            data-cy="resend-email"
            :variant="isEmailSent ? 'brand-light' : 'brand-primary'"
            @click="onResendEmail()"
            :disabled="isEmailSent"
          >
            <b-spinner small v-if="showResetPasswordLoader"></b-spinner>
            Resend email
          </b-button>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="expired-link-modal"
      centered
      hide-header
      hide-footer
      ref="expired-link-modal"
      size="sm"
      no-close-on-backdrop
    >
      <div class="d-flex flex-column" v-if="code == 400">
        <div class="message-detail text-center">
          <h5 class="msg-title">Expired Reset Link</h5>
        </div>
        <small>This reset link has expired.</small>
        <small class="mb-3"> Please request a new password reset.</small>
        <div class="d-flex justify-content-center mb-3">
          <a href="/login" class="btn btn-brand-seconday"> Close </a>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import moment from "moment";
import { required, email } from "vuelidate/lib/validators";
import { forEach } from "lodash";

export default {
  name: "Login",
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Login",
  },
  data() {
    return {
      showLoader: false,
      isFailed: false,
      loginFailedMsg: "",
      isResetpasswordFormHide: false,
      isEmailSent: false,
      code: 0,
      showResetPasswordLoader: false,
      form: {
        username: "",
        password: "",
      },
      resetPasswordForm: {
        email: "",
      },
    };
  },
  mounted() {
    this.code = this.$route.query.code;
    const message = this.$route.query.message;
    if (this.code == 400) {
      this.$refs["expired-link-modal"].show();
    } else if (this.code == 500) {
      this.$bvToast.toast(message, {
        title: "Error",
        variant: "danger",
        autoHideDelay: 5000,
        solid: true,
      });
    }
  },
  methods: {
    ...mapActions("auth", ["LogIn", "resetPassword"]),
    ...mapActions([
      "getStatusCode",
      "getModuleMasterList",
      "getUserModulerPermission",
      "getLanguages",
      "getTranslations",
      "getVersionHistory",
    ]),
    ...mapMutations("auth", {
      setItem: "setItem",
      removeSavedCredentials: "LogOut",
    }),
    ...mapMutations({
      generalSetItem: "setItem",
    }),
    async onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        return;
      }

      this.isFailed = false;

      this.showLoader = true;

      let response = await this.LogIn(this.form);
      if (response) {
        if (response.status == 0) {
          this.showLoader = false;
          this.isFailed = true;
          this.loginFailedMsg = response.message;
          return;
        }

        localStorage.setItem("lastLoginAt", moment());
        this.getStatusCode();

        const languages = await this.getLanguages();
        // await this.getTranslations({languages: languages});

        const default_en = languages.find((item) => item.languageCode == "en");

        let languageId = default_en.languageId;
        this.getVersionHistory(languageId);

        await this.getTranslations({ langId: default_en.languageId });

        let modulePermissionListResponse = await this.getModuleMasterList();
        let userModulerPermissionResponse =
          await this.getUserModulerPermission();

        let modulePermissions = {};

        forEach(modulePermissionListResponse, (module) => {
          let moduleName = module.module.replace(/\s+/g, "-").toLowerCase();
          //applied all the permission for admin user
          if (this.user.userType == this.adminUser) {
            modulePermissions[moduleName] = true;
          } else {
            //applied condition based the permission for non admin user
            modulePermissions[moduleName] = false;
            let existPermissionModule = userModulerPermissionResponse.find(
              (permission) => permission.moduleId == module.id
            );
            if (existPermissionModule) {
              modulePermissions[moduleName] =
                existPermissionModule.isAccess == 1 ? true : false;
            }
          }
        });

        this.setItem({
          resource: "userModulerPermission",
          payload: modulePermissions,
        });

        let routePath = "/";

        this.setItem({
          resource: "defaultLandingRoute",
          payload: routePath,
        });

        this.$router.push(routePath);
      } else {
        this.isFailed = true;
      }
      this.showLoader = false;
    },
    async onSubmitResetPasswordForm() {
      this.showResetPasswordLoader = true;
      this.$v.resetPasswordForm.$touch();
      if (this.$v.resetPasswordForm.$invalid) {
        this.showResetPasswordLoader = false;
        return;
      }
      const responseData = await this.resetPassword(this.resetPasswordForm);
      if (responseData.status == 200) {
        this.isEmailSent = true;
        this.isResetpasswordFormHide = true;
        setTimeout(() => {
          this.isEmailSent = false;
        }, 10000);
      }
      if (responseData.status === 500) {
        const message = responseData.message;
        this.$bvToast.toast(message, {
          title: "Error",
          variant: "danger",
          autoHideDelay: 5000,
          solid: true,
        });
      }
      this.showResetPasswordLoader = false;
    },
    async onCancelResetPasswordForm() {
      this.$refs["reset-password-modal"].hide();
      this.isResetpasswordFormHide = false;
      this.resetPasswordForm.email = "";
      this.$v.resetPasswordForm.$reset();
    },
    async onResendEmail() {
      this.showResetPasswordLoader = true;
      this.isEmailSent = true;
      this.onSubmitResetPasswordForm(this.resetPasswordForm);
      this.showResetPasswordLoader = false;
      setTimeout(() => {
        this.isEmailSent = false;
      }, 10000);
    },
  },
  computed: {
    ...mapGetters("auth", ["user", "siteAccess"]),
  },
  watch: {
    siteAccess(newVal) {
      if (newVal.length) {
        this.generalSetItem({
          resource: "userDashboardSite",
          payload: newVal[0],
        });
      }
    },
    form() {
      this.isFailed = false;
      this.loginFailedMsg = "";
    },
  },
  validations: {
    resetPasswordForm: {
      email: {
        required,
        email,
      },
    },
    form: {
      username: {
        required,
      },
      password: {
        required,
      },
    },
  },
  beforeMount() {
    this.generalSetItem({ resource: "isLoading", payload: false });
    this.generalSetItem({ resource: "userDashboardSite", payload: {} });
    this.removeSavedCredentials();
  },
};
</script>
